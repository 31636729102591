<template>
  <main class="d-flex flex-column white fill-height">
    <header class="header px-5 d-flex justify-space-between align-baseline">
      <div class="d-flex justify-space-between align-baseline">
        <div class="title font-weight-regular">
          Orders
        </div>
        <div class="filter-box mx-6">
          <v-text-field prepend-icon="mdi-magnify" label="Filter" single-line hide-details />
        </div>
      </div>
      <div>
        <v-icon class="light-blue--text text--darken-4" :size="21">mdi-settings-outline</v-icon>
        <v-btn
          tile
          outlined
          large
          color="light-blue darken-4"
          class="white--text mx-8"
          min-width="166"
        >
          Report
        </v-btn>
      </div>
    </header>
    <section class="flex-grow-1">
      <data-table
        :headers="$options.ordersHeadersConfig"
        :items="orders"
        :options.sync="options"
        :loading="isLoading"
        :items-per-page="100"
        :disable-pagination="isLoading"
        :server-items-length="serverItemsLength"
      >
        <template #header.date="{ header }">
          <v-icon class="light-blue--text text--darken-4">mdi-calendar-check</v-icon>
          {{ header.text }}
        </template>
        <template #item.status="{ item }">
          <delivery-status-label :delivery-status="item.status" />
        </template>
        <template #item.orderId="{ item }">
          <link-with-icon
            icon-url="/icons/order-icon.svg"
            :link-text="item.orderId"
            :link-to="{ name: 'OrderDetails', params: { id: item.orderId } }"
          />
        </template>
        <template #item.amount="{ item }">
          <span v-if="item.amount">{{ item.amount }}</span>
        </template>
        <template #item.appliedPromoCodeAmount="{ item }">
          <span v-if="item.appliedPromoCodeAmount">{{ item.appliedPromoCodeAmount }}</span>
        </template>
        <template #item.fee="{ item }">
          <span v-if="item.doctorFee">{{ item.doctorFee }}</span>
        </template>
        <template #item.consultationMinutesSpent="{ item }">
          <span v-if="item.consultationMinutesSpent">{{ item.consultationMinutesSpent }} min</span>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import { pathOr } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';
import DeliveryStatusLabel from '@/components/common/DeliveryStatusLabel.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';

import { getOrdersList } from '@/api/orders.api';
import {
  formatDateFieldsInArray,
  formatOrdersMoneyFields,
  transformDataTableOptionsForRequest
} from '@/utils';

import { ORDER_FIELD } from '@/constants/orders';

const DEFAULT_SORT_BY_FIELD = 'orderId';
const ORDERS_TABLE_HEADER_CONFIG = [
  { text: 'Order ID', value: 'orderId' },
  { text: 'Date', value: 'purchaseDate' },
  { text: 'Status', value: 'status' },
  { text: 'Order Name in Odoo', value: 'odooOrderName' },
  { text: 'Tracking number', value: 'trackingNumber', sortable: false },
  { text: 'Log. center', value: 'logisticCenterName' },
  { text: 'Doctor', value: 'doctorName' },
  { text: 'Customer', value: 'customerName' },
  { text: 'Amount', value: 'amount', sortable: false },
  { text: 'Promo code', value: 'appliedPromoCodeAmount', sortable: false },
  { text: 'Consult. time', value: 'consultationMinutesSpent', sortable: false },
  { text: "Dr's fee", value: 'doctorFee' }
];

const DEFAULT_TABLE_OPTIONS = {
  sortBy: [ORDER_FIELD.PURCHASE_DATE]
};

export default {
  name: 'OrdersPage',
  components: { LinkWithIcon, DeliveryStatusLabel, DataTable },
  ordersHeadersConfig: ORDERS_TABLE_HEADER_CONFIG,
  data() {
    return {
      options: DEFAULT_TABLE_OPTIONS,
      isLoading: false,
      orders: [],
      serverItemsLength: 0
    };
  },
  watch: {
    async options() {
      this.orders = await this.getOrders();
    }
  },
  methods: {
    async getOrders() {
      try {
        this.isLoading = true;

        const {
          page,
          sortType = DEFAULT_SORT_BY_FIELD,
          sortDirection,
          size
        } = transformDataTableOptionsForRequest(this.options);

        const promoCodeFilter = pathOr(null, ['query', 'promoCodeFilter'], this.$route);

        const {
          data: { orders, ordersTotal }
        } = await getOrdersList({ page, sortType, sortDirection, promoCodeFilter, size });

        this.serverItemsLength = ordersTotal;

        const ordersWithFormattedMoneyFields = formatOrdersMoneyFields(orders);

        return formatDateFieldsInArray(ordersWithFormattedMoneyFields, ['creationDate']);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px;
}

.filter-box {
  width: 300px;
}
</style>
