var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"d-flex flex-column white fill-height"},[_c('header',{staticClass:"header px-5 d-flex justify-space-between align-baseline"},[_c('div',{staticClass:"d-flex justify-space-between align-baseline"},[_c('div',{staticClass:"title font-weight-regular"},[_vm._v(" Orders ")]),_c('div',{staticClass:"filter-box mx-6"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""}})],1)]),_c('div',[_c('v-icon',{staticClass:"light-blue--text text--darken-4",attrs:{"size":21}},[_vm._v("mdi-settings-outline")]),_c('v-btn',{staticClass:"white--text mx-8",attrs:{"tile":"","outlined":"","large":"","color":"light-blue darken-4","min-width":"166"}},[_vm._v(" Report ")])],1)]),_c('section',{staticClass:"flex-grow-1"},[_c('data-table',{attrs:{"headers":_vm.$options.ordersHeadersConfig,"items":_vm.orders,"options":_vm.options,"loading":_vm.isLoading,"items-per-page":100,"disable-pagination":_vm.isLoading,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"light-blue--text text--darken-4"},[_vm._v("mdi-calendar-check")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('delivery-status-label',{attrs:{"delivery-status":item.status}})]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('link-with-icon',{attrs:{"icon-url":"/icons/order-icon.svg","link-text":item.orderId,"link-to":{ name: 'OrderDetails', params: { id: item.orderId } }}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(item.amount))]):_vm._e()]}},{key:"item.appliedPromoCodeAmount",fn:function(ref){
var item = ref.item;
return [(item.appliedPromoCodeAmount)?_c('span',[_vm._v(_vm._s(item.appliedPromoCodeAmount))]):_vm._e()]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(item.doctorFee)?_c('span',[_vm._v(_vm._s(item.doctorFee))]):_vm._e()]}},{key:"item.consultationMinutesSpent",fn:function(ref){
var item = ref.item;
return [(item.consultationMinutesSpent)?_c('span',[_vm._v(_vm._s(item.consultationMinutesSpent)+" min")]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }